<template>
  <b-container fluid>
    <b-overlay
      :show="isLoading"
      rounded="sm"
    >
      <b-card no-body>
        <b-card-header class="py-1">
          <b-card-title>Danh sách điểm chung</b-card-title>
          <div class="text-right">
            <b-button
              v-if="creatable"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-modal.generalScoreSaveModal
              variant="success"
              class="mt-0 ml-1"
              @click="onCreateMainData"
            >
              <span class="text-nowrap text-right">
                <feather-icon icon="PlusIcon" /> Thêm mới
              </span>
            </b-button>
          </div>
        </b-card-header>
        <b-card-body class="pb-1">
          <b-row :class="{'mt-1': creatable}">
            <b-col cols="12">
              <vue-good-table
                line-numbers
                :columns="columns"
                :rows="dataSources"
                :row-style-class="rowStyleClassFn"
                :pagination-options="paginationOptions"
                @on-row-click="onRowClick"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'status'">
                    {{ getStatusName(props.row.status) }}
                  </span>
                  <span v-else-if="props.column.field === 'type'">
                    <b-badge :variant="props.row.type === 1 ? 'light-primary' : 'light-success'">
                      {{ getScoreType(props.row.type) }}
                    </b-badge>
                  </span>
                  <span v-else-if="props.column.field === 'isExamScore'">
                    <b-badge :variant="props.row.isExamScore === 1 ? 'light-success' : ''">
                      <feather-icon :icon="props.row.isExamScore === 1 ? 'CheckIcon' : ''" />
                    </b-badge>
                  </span>
                  <span v-else-if="props.column.field === 'action'">
                    <b-button
                      v-if="updatable"
                      v-b-modal.generalScoreSaveModal
                      variant="primary"
                      class="btn-icon"
                      size="sm"
                      @click="onEdit(props.row)"
                    >
                      <feather-icon icon="Edit2Icon" />
                    </b-button>
                    <b-button
                      v-if="deletable"
                      variant="danger"
                      class="btn-icon"
                      style="margin-left: 6px"
                      size="sm"
                      @click="onDelete(props.row)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </span>
                  <!-- Column: Common -->
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị {{ itemsPerPage * (currentPage - 1) + 1 }}
                        đến {{ itemsPerPage * currentPage }} &nbsp; của {{ props.total }} bản ghi
                      </span>
                    </div>

                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap">Hiển thị</span>
                      <b-form-select
                        v-model="itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap">bản ghi/trang</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-overlay>

    <ComponentScore
      v-if="componentScoreReadable"
      :scoring-formula-id="scoringFormulaId"
      :general-score-id="currentMainData ? currentMainData.id : null"
      :review-board-name="currentMainData ? currentMainData.name : ''"
      @onLoading="setLoading"
      @succeed="onSucceed"
    />

    <GeneralScoreSave
      ref="generalScoreSaveFormRef"
      :scoring-formula-id="scoringFormulaId"
      :main-data="currentMainData"
      @succeed="onSucceed"
    />
  </b-container>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import GeneralScoreSave from '@/views/admin/scoring-formula/general/GeneralScoreSave.vue'
import { STATUSES } from '@/const/status'
import ComponentScore from '@/views/admin/scoring-formula/component/ComponentScore.vue'
import { SCORE_TYPES } from '@/const/type'

export default {
  name: 'GeneralScore',
  directives: {
    Ripple,
  },
  components: {
    ComponentScore,
    GeneralScoreSave,
    BButton,
    BCard,
    BRow,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    VueGoodTable,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BBadge,
  },
  props: {
    scoringFormulaId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      itemsPerPage: 10,
      currentMainData: undefined,
      itemsPerPageOptions: [10, 20, 30, 50, 100],
      columns: [
        {
          label: 'Tên điểm',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên điểm',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Hệ số',
          field: 'coefficient',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Loại điểm',
          field: 'type',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: SCORE_TYPES,
            placeholder: 'Chọn loại điểm',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Là điểm thi',
          field: 'isExamScore',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Số điểm thành phần',
          field: 'countComponentScore',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Thứ tự',
          field: 'orderNo',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: STATUSES.map(status => ({ value: status.value, text: status.label })),
            placeholder: 'Chọn trạng thái',
          },
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'generalScore/dataLists',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    creatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.GENERAL_SCORE)
    },
    updatable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.GENERAL_SCORE)
    },
    deletable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.GENERAL_SCORE)
    },
    componentScoreReadable() {
      return isSystemAdmin() || hasPermissionForResource(PermissionCode.READ, ResourceCode.COMPONENT_SCORE)
    },
  },
  async created() {
    this.isLoading = true
    try {
      await this.getMainDataFromStore()
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: 'generalScore/getData',
      deleteMainData: 'generalScore/deleteData',
    }),
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData({ scoringFormulaId: this.scoringFormulaId })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },
    getScoreType(value) {
      return SCORE_TYPES.find(type => type.value === value).text
    },
    async onSucceed() {
      await this.getMainDataFromStore()
    },
    onRowClick(param) {
      const { row } = param
      this.setCurrentMainData(row)
    },
    setCurrentMainData(data) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = data
      this.currentMainData = rest
    },
    onCreateMainData() {
      this.currentMainData = undefined
    },
    onEdit(mainData) {
      this.setCurrentMainData(mainData)
    },
    onDelete(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa điểm <span class="text-danger">${mainData.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.isLoading = true
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                await this.getMainData({ scoringFormulaId: this.scoringFormulaId })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.isLoading = false
          }
        }
      })
    },
    rowStyleClassFn(row) {
      return this.currentMainData && row.id === this.currentMainData.id ? 'row-selected' : ''
    },
    setLoading(value) {
      this.isLoading = value
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';

.row-selected {
  background-color: rgba(251, 205, 68, 0.89);
  color: #FFF;
}
</style>
