<template>
  <b-card no-body>
    <b-card-header class="py-1">
      <b-card-title>
        Danh sách điểm thành phần <span class="text-primary">{{ generalScoreName }}</span>
      </b-card-title>
      <div class="text-right">
        <b-button
          v-if="creatable"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mt-0 ml-1"
          @click="onCreateMainData"
        >
          <span class="text-nowrap text-right">
            <feather-icon icon="PlusIcon" /> Thêm mới
          </span>
        </b-button>
      </div>
    </b-card-header>
    <b-card-body class="pb-1">
      <b-row :class="{'mt-1': creatable}">
        <b-col cols="12">
          <vue-good-table
            line-numbers
            :columns="columns"
            :rows="dataSources"
            :pagination-options="paginationOptions"
          >
            <div
              slot="emptystate"
              style="text-align: center; font-weight: bold"
            >
              Không có bản ghi nào !
            </div>
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field === 'status'">
                {{ getStatusName(props.row.status) }}
              </span>
              <span v-else-if="props.column.field === 'isAttendance'">
                <b-badge :variant="props.row.isAttendance === 1 ? 'light-success' : ''">
                  <feather-icon :icon="props.row.isAttendance === 1 ? 'CheckIcon' : ''" />
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'action'">
                <b-button
                  v-if="updatable"
                  v-b-modal.componentScoreSaveModal
                  variant="primary"
                  class="btn-icon"
                  size="sm"
                  @click="onEdit(props.row)"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-button
                  v-if="deletable"
                  variant="danger"
                  class="btn-icon"
                  style="margin-left: 6px"
                  size="sm"
                  @click="onDelete(props.row)"
                >
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </span>
              <!-- Column: Common -->
              <span v-else>{{ props.formattedRow[props.column.field] }}</span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Hiển thị {{ itemsPerPage * (currentPage - 1) + 1 }}
                    đến {{ itemsPerPage * currentPage }} &nbsp; của {{ props.total }} bản ghi
                  </span>
                </div>

                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">Hiển thị</span>
                  <b-form-select
                    v-model="itemsPerPage"
                    :options="itemsPerPageOptions"
                    class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap">bản ghi/trang</span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="itemsPerPage"
                    class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })"
                  />
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
    </b-card-body>

    <ComponentScoreSave
      :scoring-formula-id="scoringFormulaId"
      :general-score-id="generalScoreId"
      :main-data="currentMainData"
      @succeed="onSucceed"
    />
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormSelect,
  BPagination,
  BRow,
  BCardHeader,
  BCardTitle,
  BCardBody, BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import ComponentScoreSave from '@/views/admin/scoring-formula/component/ComponentScoreSave.vue'
import { STATUSES } from '@/const/status'
import {getUser} from "@/auth/utils";

export default {
  name: 'ComponentScore',
  directives: {
    Ripple,
  },
  components: {
    BBadge,
    ComponentScoreSave,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormSelect,
    BPagination,
    VueGoodTable,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  props: {
    scoringFormulaId: {
      type: Number,
      default: null,
    },
    generalScoreId: {
      type: Number,
      default: null,
    },
    generalScoreName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 10,
      currentMainData: undefined,
      itemsPerPageOptions: [10, 20, 30, 50, 100],
      user: getUser(),
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'componentScore/dataLists',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.COMPONENT_SCORE)
    },
    updatable() {
      return hasPermissionForResource(PermissionCode.UPDATE, ResourceCode.COMPONENT_SCORE)
    },
    deletable() {
      return hasPermissionForResource(PermissionCode.DELETE, ResourceCode.COMPONENT_SCORE)
    },
    columns() {
      const orgCode = this.user.orgCode
      const cl =  [
        {
          label: 'Tên điểm',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên điểm',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Áp dụng từ số TC',
          field: 'minThreshold',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: 'Là điểm chuyên cần',
          field: 'isAttendance',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Thứ tự',
          field: 'orderNo',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Trạng thái',
          field: 'status',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: STATUSES.map(status => ({ value: status.value, text: status.label })),
            placeholder: 'Chọn trạng thái',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          tdClass: 'text-center',
        },
      ]
      const item = {
        label: 'Hệ số',
        field: 'coefficient',
        sortable: true,
        thClass: 'text-center',
        tdClass: 'text-right',
      }
      if (orgCode === 'CDSPHOABINH') {
        cl.splice(1, 0, item)
      }
      return cl
    }

  },
  watch: {
    async generalScoreId(val) {
      val ? await this.getMainDataFromStore() : this.setMainData([])
    },
  },
  methods: {
    ...mapMutations({
      setMainData: 'componentScore/SET_LISTS',
    }),
    ...mapActions({
      getMainData: 'componentScore/getData',
      deleteMainData: 'componentScore/deleteData',
    }),
    onCreateMainData() {
      if (!this.generalScoreId) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Vui lòng chọn điểm chung',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }
      this.currentMainData = undefined
      this.$bvModal.show('componentScoreSaveModal')
    },
    onEdit(mainData) {
      const {
        rowNum,
        originalIndex,
        vgt_id,
        ...rest
      } = mainData
      this.currentMainData = rest
    },
    onDelete(mainData) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa điểm thành phần <span class="text-danger">${mainData.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$emit('onLoading', true)
          try {
            const response = await this.deleteMainData(mainData.id)
            if (response) {
              const { isSuccessful, message } = response
              if (isSuccessful) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
                await this.getMainData({ generalScoreId: this.generalScoreId })
                this.$emit('succeed')
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: message,
                    icon: 'XCircleIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          } catch (e) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `[${e.code}] ${e.message}`,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          } finally {
            this.$emit('onLoading', false)
          }
        }
      })
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.$emit('onLoading', true)
      try {
        await this.getMainData({ generalScoreId: this.generalScoreId })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.$emit('onLoading', false)
      }
    },
    getStatusName(id) {
      return STATUSES.find(status => status.value === id).label
    },
    async onSucceed() {
      await this.getMainDataFromStore()
      this.$emit('succeed')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
